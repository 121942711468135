import React from "react"
import { Link } from "gatsby"

import LayoutShort from "../components/layout_short"
import Seo from "../components/seo"

const thanksHtml = ({data,location}) => {
  return(
        
	<LayoutShort>
      <Seo
		pagetitle="お問い合せありがとうございます"
		pagedesc="MAZINへのお問い合せ完了ページ"
		pagepath={location.pathname}
	  />
    <div className="container-fluid thanks-error thanks">
        <div className="row">
          <div className="second-top about-mazin bg-img_cover black-filter">
            <div className="contents-title contents-title_white">THANK<br className="d-md-none" /> YOU</div>
            <div className="contents-box">
              <h3 className="underline">お問い合せありがとうございます</h3><div />
              <div className="contents-comment">
                <div className="comment-br">
                  この度は、お問い合せをいただきまして誠にありがとうございます。<br />
                  内容確認後、担当者より折り返しご連絡させていただきますので今暫くお待ちください。
                </div>
              </div>{/*.contents-comment*/}
            </div>{/*.contents-box*/}
          </div>{/*.second-top*/}
              <Link to={`/`}>
                <div className="link-top">TOP</div>
              </Link>
        </div>{/*.row*/}
    </div>{/*.container-fluid*/}
    
	</LayoutShort>

  )
}

export default thanksHtml